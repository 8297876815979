import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import FAQAccordion from '../components/faqAccordion'

const InfoPage = ({ data: { allFaqYaml: { nodes } }}) => (
  <Layout>
    <SEO title="Information" />

    <div className="info-container">
      <h2 className="page-heading">
        Info
      </h2>

      <FAQAccordion
        faq={ nodes }
      />
    </div>
  </Layout>
)

export const query = graphql`
  query InfoPageQuery {
    allFaqYaml {
      nodes {
        question
        answer
      }
    }
  }
`

export default InfoPage
