import React from 'react'
import PropTypes from 'prop-types'

class FAQAccordion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      faq: this.props.faq
    }

    this.toggleQuestion = this.toggleQuestion.bind(this)
  }

  toggleQuestion(index) {
    const faq = Array.from(this.state.faq)
    faq[index].collapsed = !faq[index].collapsed
    this.setState({
      ...this.state,
      faq: faq
    })
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      faq: this.state.faq.map(question => {
        question.collapsed = true;
        return question
      })
    })
  }

  render() {
    const { faq } = this.state;

    return (
      <div className="info-faq">
        {faq.map((question, index) => (
          <div
            className={ `info-faq--item ${question.collapsed ? '' : 'opened'}` }
            key={ index }
          >
            <div className="info-faq--head" onClick={ () => this.toggleQuestion(index) }>
              <div className="info-faq--question">
                { question.question }
              </div>
              <div className={ question.collapsed ? 'info-faq--open' : 'info-faq--collapse' } />
            </div>

            <div className="info-faq--answer">
              { question.answer }
            </div>
          </div>
        ))}
      </div>
    )
  }
}

FAQAccordion.propTypes = {
  faq: PropTypes.array.isRequired,
}

export default FAQAccordion